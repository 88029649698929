import * as React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';

export default function About({ data, location }) {
	return (
		<Layout location={location} lang="bg">
			<Seo title="About" />
			<div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
				<div className="flex flex-col-reverse flex-col items-center justify-between lg:flex-row">
					<div className="relative lg:w-1/2">
						<GatsbyImage
							className="object-cover w-full shadow-lg"
							image={
								data.allMarkdownRemark.nodes[0].frontmatter.aboutImage
									.childImageSharp.gatsbyImageData
							}
							alt="About Art"
						/>
					</div>
					<div className="mb-10 lg:max-w-lg lg:mb-0">
						<div className="max-w-xl mb-6">
							<h2 className="max-w-lg mb-6 text-4xl tracking-tight text-gray-900 dark:text-white sm:text-5xl sm:leading-none">
								За Ателие Мотив
							</h2>
							<p className="text-base text-gray-700 dark:text-white md:text-lg">
								Ателие Мотив е студио за интериорен дизайн, архитектура и
								изкуство ситуирано в град Пловдив. Всеки проект има мотив,
								основна идея и за нас тя е да намерим баланса между вашите мечти
								и нужди и какво е функционално и естетически издържано,
								използвайки точните материали, красиви картини и декорации. Няма
								прекалено големи или малки проекти, нашата специалност са
								частните домове, но ако имате офис пространство, ресторант или
								хотел, които да имат нужда от проектиране, ние ще ви помогнем.
								Не се ограничаваме само в един аспект от работата и можем да ви
								преведем през целия процес от проектиране до реализация.
							</p>
							<h3 className="mb-3 pt-1 text-base text-gray-700 dark:text-white md:text-xl">
								Нашите услуги включват:
							</h3>
							<ul className="-ml-1 space-y-2 text-base text-gray-700 dark:text-white">
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Интериорен дизайн
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Архитектура
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Изкуство
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Мебелен дизайн и изработка
								</li>
								<li className="flex items-start">
									<span className="mr-1">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth={2}
												d="M5 13l4 4L19 7"
											/>
										</svg>
									</span>
									Проектна реализация
								</li>
							</ul>
							<h3 className="mb-3 pt-1 text-base text-gray-700 dark:text-white md:text-xl">
								Нека да започнем.
							</h3>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
}

export const aboutQuery = graphql`
	query {
		allMarkdownRemark(
			filter: { frontmatter: { aboutImage: { id: { ne: null } } } }
		) {
			nodes {
				frontmatter {
					aboutImage {
						childImageSharp {
							gatsbyImageData(formats: [AUTO, WEBP, AVIF])
						}
					}
				}
			}
		}
	}
`;
